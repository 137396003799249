import React, { useState } from "react";
import ReactDatamaps from "react-india-states-map";
import "../App.css"; // Import your CSS file for specific styling

const STATES = {
  Maharashtra: {
    value: 50,
  },
  Uttarakhand: {
    value: 150,
  },
  Haryana: {
    value: 520,
  },
  Bihar: {
    value: 350,
  },
};

const IndiaMap = () => {
  const [activeState, setActiveState] = useState({
    data: STATES.Maharashtra,
    name: "India",
  });

  const [stateLists, setStateLists] = useState(STATES);

  const handleStateEvent = (data, name) => {
    setActiveState({ data, name });
  };

  return (
    <div className="india-map-container">
      <ReactDatamaps
        regionData={stateLists}
        mapLayout={{
          hoverTitle: "Count",
          noDataColor: "#D36418",
          borderColor: "#ffffff",
          hoverBorderColor: "pink",
          hoverColor: "green",
        }}
        hoverComponent={({ value }) => {
          const stateData = stateLists[value.name];
          return (
            <>
            {stateData && (
                <>
                  <p>{stateData.value}</p>
                </>
              )}
              <p>{value.name}</p>
              
            </>
          );
        }}
        onClick={(data, name) => handleStateEvent(data, name)}
        onMouseOver={(data, name) => handleStateEvent(data, name)}
        activeState={activeState}
      />
    </div>
  );
};

export default IndiaMap;
