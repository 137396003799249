import React from "react";
import "./index.css";
import Barchart from "../TransportVehicle/Barchart";
import TripleBarchart from "./TripleBarchart";
import { MotionAnimate } from "react-motion-animate";

function index() {

  const data = [
    {
      name: "High PV EV30",
      pv: 55,
      amt: 76,
    },
    {
      name: "BAU",
      pv: 41,
      amt: 87,
    },
    {
      name: "EV 30",
      pv: 35,
      amt: 73,
    },
    {
      name: "Shared EV30",
      pv: 27,
      amt: 71,
    },
    {
      name: "High PT- EV30",
      pv: 22,
      amt: 67,
    },
  ];

  const data2 = [
    {
      heading: "16 MtCO",
      subHeading: "of GHG emission reduced",
      marginLeft: "66%",
      color: "#554AD8",
      fontColor: "#FEFEFE",
      icon: require("../../images/icon6.png"),
      arrow: require("../../images/arrowUp.png"),
    },
    {
      heading: "17% of PM and NOx 18% of CO emission",
      subHeading: "reduced",
      marginLeft: "56.4%",
      color: "#7268E8",
      fontColor: "#FEFEFE",
      icon: require("../../images/icon5.png"),
      arrow: require("../../images/arrowUp.png"),
    },
    {
      heading: "INR 1.1 lakh crore",
      subHeading:
        "worth of savings with 15% reduction in crude oil impact bill",
      marginLeft: "46.8%",
      color: "#8C84ED",
      fontColor: "#FEFEFE",
      icon: require("../../images/icon4.png"),
      arrow: require("../../images/arrowUp.png"),
    },
    {
      heading: "INR 2.1 lakh jobs",
      subHeading:
        "of value-add generated in EV powertrain, battery and charger manufacturing and electricity generation sector",
      marginLeft: "37.2%",
      color: "#ACA5FF",
      fontColor: "#554AD8",
      icon: require("../../images/icon3.png"),
    },
    {
      heading: "1.2 lakh jobs",
      subHeading:
        "created in EV powertrain, battery and charger manufacturing and electricity generation sector",
      marginLeft: "27.6%",
      color: "#D0CCFF",
      fontColor: "#554AD8",
      icon: require("../../images/icon2.png"),
    },
    {
      heading: "9% to 20%",
      subHeading:
        "lower TCO for EV users compared to ICE vehicles in the case of 2 W. 3W, and buses",
      marginLeft: "18%",
      color: "#E3E5FF",
      fontColor: "#554AD8",
      icon: require("../../images/icon1.png"),
      txt: "Multiplier effect of people spending saved money on other goods and services",
      gain: require("../../images/gain.png"),
    },
  ];
  
  const sentenceStyle = {
    display: "inline",
    color: "#7291DE",
  };

  return (
    <div className="pollution-map-container">
      <div style={{ marginBottom: "5%",marginTop:'3%' }}>
        <div style={{ position: "absolute", marginLeft: "9.5%", width: 500 }}>
          <h2 style={{ margin: 0 }} className="topHead">
            Target EV standard CR MIX India{" "}
            <span style={sentenceStyle}> | 2023 </span>
          </h2>
          <p
            style={{ padding: 0, margin: 0, marginTop: 10 }}
            className="topSub"
          >
            If EVs garner 30 per cent share of India’s vehicle sales by 2030, it
            would result in a slew of gains
          </p>
        </div>
        {data2.map((item, index) => {
          return (
            <div style={{ width: "100%", display: "flex",position:'relative' }}>
              <div
                style={{
                  marginLeft: item.marginLeft,
                  width: "16%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    backgroundColor: item.color,
                    padding: 5,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 5,
                    height: 100,
                    justifyContent: "center",
                  }}
                >
                  <h3
                    style={{ maxWidth: "70%", marginLeft: 10 }}
                    className="boxHeading"
                  >
                    {item.heading}
                  </h3>
                  <p style={{ color: item.fontColor }} className="boxPara">
                    {item.subHeading}
                  </p>
                </div>

                <div
                  style={{
                    position: "absolute",
                    right: "105%",
                    top: 15,
                    display: "flex",
                  }}
                >
                  <MotionAnimate
                          variant={{
                            hidden: { opacity: 0.2, rotate: -180 },
                            show: {
                              opacity: 0.8,
                              rotate: 0,
                              transition: {
                                repeat: Infinity,
                                duration: 4,
                                repeatDelay: 1,
                                type: 'spring'
                              }
                            }
                          }}>
                  <img
                    src={item.icon}
                    alt="icons"
                    style={{ width: 75, height: 80 }}
                  />
                  </MotionAnimate>
                  {item.arrow && (
                    <img
                      src={item.arrow}
                      alt="icons"
                      style={{ height: 80, marginLeft: 10 }}
                    />
                  )}
                </div>
              </div>
              {item.txt && (
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <p
                    style={{ color: "white", maxWidth: "50%" }}
                    className="boxPara"
                  >
                    {item.txt}
                  </p>
                </div>
              )}
              {item.gain && (
                <img
                  src={item.gain}
                  alt="gain"
                  style={{
                    position:'absolute',
                    bottom:0,
                    right:'18%',
                    height: 50,
                    width: 160,
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <h1 className="heading">Climate Data Integration (Energy Demand) </h1>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "75%",
          marginTop: 30,
        }}
      >
        <p className="subHeading">
          The consumption of petrol and diesel is highest in the high private
          vehicle mobility scenario and lowest in the high public transport
          mobility scenario
        </p>
      </div>
      <div className="img">
        <h4>Outcome of 30% of EV penetration in 2030 </h4>
        <Barchart data={data} />
      </div>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "85%",
          marginTop: 30,
        }}
      >
        <p className="subHeading">
          The central government would lose 15–31 per cent of potential
          petroleum tax revenues (otherwise obtained in a BAU scenario) in 2030
          in multiple scenarios we assume for 2030 (Figure 18). The combined
          state government revenues from petroleum tax could dip by 15–33 per
          cent under various scenarios compared to BAU. On the other hand, in a
          high private vehicle scenario, the central and state governments,
          respectively, gain a revenue of 4 and 8 per cent in the form of fuel
          taxes. The loss or gain in fuel tax revenue, which is a reflection of
          consumer spend on petrol and diesel, should be viewed from a larger
          macroeconomic context. The consumer spending less on fuel is left with
          a higher disposable income, which, in turn, is expected to increase
          the consumption of other goods and services in the economy (Cambridge
          Econometrics 2015).
        </p>
      </div>
      <h2 className="headingMid">
        Energy demand is about 12 per cent lower in case of 30 per cent EV
        penetration compared to a BAU scenario in 2030{" "}
      </h2>
      <div style={{ marginTop: 50 }} className="img">
        <h4>Outcome of 30% of EV penetration in 2030 </h4>
        <TripleBarchart />
      </div>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "85%",
          marginTop: 30,
        }}
      >
        <p className="subHeading">
          We then explored the outcomes of 30 per cent EV sales under each of
          the assumed scenarios as shown in Figure 14. Unsurprisingly, we find
          that energy consumption is highly reduced under high public transport
          and high shared mobility scenarios (27 per cent and 20 per cent
          respectively). While fuel consumption benefits are comparable for both
          these scenarios, but in case of a high public transport scenario,
          there would be 24 per cent fewer passenger vehicles on road compared
          to a high shared mobility scenario in 2030. Considering the benefits
          of better road-space use, lower parking space requirements, and
          reduced traffic congestion, a high public transport scenario is the
          preferred mobility scenario for India by 2030.
        </p>
      </div>
    </div>
  );
}

export default index;
