import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '2019',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2020',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '2021',
    uv: 2000,
    pv: 5800,
    amt: 2290,
  },
  {
    name: '2022',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: '2023',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: '2024',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: '2025',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '2026',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '2027',
    uv: 3790,
    pv: 4100,
    amt: 2800,
  },
  {
    name: '2028',
    uv: 2390,
    pv: 4300,
    amt: 3100,
  },
  {
    name: '2029',
    uv: 4490,
    pv: 4100,
    amt: 3100,
  },
  {
    name: '2030',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p style={{ color: 'black' }}>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';
  

  render() {
    return (
        <div style={{ width: '100%', height: '400px', border: '1px solid #ddd', padding: '40px',borderRadius:20 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="pv" stroke="#FFD54F" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="uv" stroke="#77BC9C" activeDot={{ r: 10 }} />
            <Line type="monotone" dataKey="amt" stroke="#ACA5FF" activeDot={{ r: 10 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
