import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

// Import your background image or provide its URL
import backgroundImage from "../../images/backgroundBlur.png";

const BarChartComp = ({ data,width}) => {
  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    position: "relative", // Set position to relative to allow stacking of elements
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p style={{ color: 'white' ,fontWeight:'600',fontSize:16 }}>{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <ResponsiveContainer
      width={width || "50%"}
      style={{ ...containerStyle }}
    >
      <BarChart
        data={data.value}
        margin={{
          top: 60,
          right: 40,
          left: 40,
          bottom: 40,
        }}
      >
        <XAxis dataKey="name" stroke="white"/>
        <YAxis stroke="white"/>
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="uv" fill={'rgba(0,0,0,0)'} barSize={30} stroke="white" strokeWidth={2}>
          <LabelList dataKey="uv" position="top" fill="white" fontSize={14} style={{fontWeight:'bold'}} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComp;
