import React, { useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  defs,
  linearGradient,
  stop,
} from 'recharts';

const Example = ({ data, width }) => {
  const [chartWidth] = useState(500);
  const [chartHeight] = useState(450);

  
  const renderCustomizedLabel = (props) => {
    const { x, y, value } = props;
    // Adjust the y position to move the label upwards
    const adjustedX = x - 40;
    const adjustedY = y - 30; // Example adjustment, you can adjust as needed
  
    return (
      <foreignObject x={adjustedX} y={adjustedY} width="100" height="100">
        <div style={{ backgroundColor: '#4C3D90', padding: 5, borderRadius: 5 ,}}>
          <text style={{color:'white',alignSelf:'center'}} fontSize={16} fontWeight={'700'} textAnchor="left">
            {value}
          </text>
        </div>
      </foreignObject>
    );
  };
  
  

  return (
    <div style={{ width: width || '100%', height: '400px', border: '1px solid #ddd', padding: '30px', borderRadius: 20 }}>
      <ResponsiveContainer width={"100%" || width} height="100%" >
        <AreaChart
          width={chartWidth}
          height={chartHeight}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#8F78F7" />
              <stop offset="100%" stopColor="rgba(190, 193, 237, 0.30)" />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 40, right: 40 }} />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="url(#gradient)" strokeWidth={3}>
            <LabelList dataKey="uv" position="top" content={renderCustomizedLabel} />
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Example;
