import React, { useState } from "react";
import { Scrollama, Step } from "react-scrollama";
import injectSheet from "react-jss";
import Barchart from "./Barchart";
import '../scrollama/index.css'

const styles = {
  main: {
    display: "grid",
    fontFamily: "Helvetica",
    gridTemplateColumns:'55% 1fr' ,
    width:'100%'
  },
  graphic: {
    position: "sticky",
    width: "100%",
    padding: "5rem 0",
    height:550,
    top: "150px",
    marginLeft:50,
    "& p": {
      fontSize: "5rem",
      textAlign: "center",
      color: "#fff",
    },
  },
  scroller: {
    display:'grid',
    paddingTop:50
  },
  step: {
    margin: "0 auto 2rem auto",
    paddingBottom: 500,
    "& p": {
      textAlign: "center",
      padding: "1rem",
      fontSize: "1.5rem",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
};


const NewData = [
    {
      name: "Buses",
      amt: 100,
    },
    {
      name: "Commercial vehicles",
      amt: 70,
    },
    {
      name: "Private cars",
      pv: 30,
      amt: 40,
    },
    {
      name: "2- and 3-wheelers",
      amt: 80,
    },
  ];

const Content = [{
    txt:`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
    enim ad minim veniam, quis nostrud exercitation ullamco laboris
    nisi.`,
},
{
    txt:` Lorem ipsum dolor sit amet consectetur. Tellus nulla sem sit
    diam eget ullamcorper nec vel. Ac volutpat mattis iaculis ipsum
    cursus a. In quis egestas vestibulum quam laoreet tincidunt
    tincidunt neque viverra. Duis morbi ut augue tincidunt.`,
},
{
    txt:`Lorem ipsum dolor sit amet consectetur. Tellus nulla sem sit
    diam eget ullamcorper nec vel. Ac volutpat mattis iaculis ipsum
    cursus a. In quis egestas vestibulum quam laoreet tincidunt
    tincidunt neque viverra. Duis morbi ut augue tincidunt.`,
}
]

const ScrollamaComp = ({ classes }) => {
  const [data, setData] = useState(0);


  const onStepEnter = ({ element, data }) => {
    setData(data);
  };

  const sentenceStyle = {
    display: "inline",
    color: "#7291DE",
  };

  return (
    <div className={classes.main}>
        <div className={classes.graphic}>
      <Barchart data={NewData} width={"80%"} />
       
      </div>
      <div className={classes.scroller}>
        <Scrollama onStepEnter={onStepEnter}  offset={0.45} >
            <h1>
              Target eV standard cR MIX India{" "}
              <span style={sentenceStyle}> | 2023 </span>
            </h1>
          {Content.map((value,index) => (
            <Step data={value} key={value}>
                 <div className="container">
        <div className="text-container">
          <div style={{ width: "65%" }}>
            
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <p
                style={{ margin: 0, padding: 0, marginTop: "15%" }}
                className="subHeading2"
              >
               {value?.txt}
              </p>
            </div>
          </div>
        </div>
      </div>
              
            </Step>
          ))}
        </Scrollama>
      </div>
      
    </div>
  );
};

export default injectSheet(styles)(ScrollamaComp);
