import React from "react";
import "./index.css";
import TripleLineChart from "./TripleLineChart";
import AreaChart from "../TransportVehicle/AreaChart";

const data = [
  {
    name: "FY16",
    uv: 22000,
  },
  {
    name: "FY17",
    uv: 27000,
  },
  {
    name: "FY18",
    uv: 56000,
  },
  {
    name: "FY19",
    uv: 129600,
  },
  {
    name: "FY20",
    uv: 155400,
  },
];

function index() {
  return (
    <div className="pollution-map-container">
      <h1 className="heading">Market Trends EV </h1>
      <h4 className="subTop">Global EV market trend </h4>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "75%",
        }}
      >
        <p className="subHeading">
          The growing pollution in India and its agreement to the Paris
          Agreement (which aims to limit global warming to 1.5°C by the end of
          this century) has made the country set a target for clean energy.
          India has committed to achieve a net zero emissions economy by 2070.
          The Indian government has also set a target to achieve 30 percent
          electrification of the country’s vehicle fleet by 2030. Presently,
          India is the third largest automobile market globally in terms of
          sales, ahead of Germany. Several schemes like FAME-2 (Rs 5,172 crore
          for FY 2023-24) and other Production Linked Incentive Scheme (PLI)
          have been launched by the Central Government to foster electric
          vehicles (EVs) industry in the country. India has 6.41% EV adoption
          rate this year as compared to 4.75% in 2022. The total unit of EVs
          sales in 2023 (till Oct 27), 2022, and 2021 are 12,03,941, 10,24,804,
          and 3,31,482 respectively.
        </p>
      </div>
      <div style={{ marginTop: 50 }} className="img">
        <TripleLineChart />
        <h4 style={{ marginRight: "40%" }}>
          Source: European Automobile Manufacturers Association(ACEA)
        </h4>
      </div>
      <h3 className="subTop">Past years trend of EV Sales in India</h3>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "75%",
        }}
      >
        <p className="subHeading">
          For our projections, we explore three ‘marker’ scenarios compared to a
          business-as-usual (BAU) situation by 2030. These three scenarios we
          assume have (i) high public transport share, (ii) high private vehicle
          share, (iii) and high shared mobility. While a change in mode-share is
          partially influenced by economic growth (and associated paradigms), in
          our scenarios, we assume that it is mainly the outcome of mobility
          policy and behaviour change interventions. We characterise these
          marker scenarios by keeping overall passenger travel demand and the
          gross domestic product (GDP) constant between scenarios. We have taken
          a cue from a few studies that explore such scenarios, although keeping
          these two variables constant is a limitation of our research. To
          exercise control over the combination of underlying assumptions and to
          create the aforementioned scenarios, we developed a vehicle stock
          model. Based on
        </p>
      </div>
      <div style={{ marginTop: 50 }} className="img">
        <AreaChart data={data} />
      </div>
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "75%",
          marginTop:30
        }}
      >
        <p className="subHeading">
          outputs from our vehicle stock model, we further estimate fuel
          consumption in these three scenarios. Using the projected vehicle
          stock in 2030, we estimate passenger and vehicle kilometres travelled
          (PKM and VKT) under various future scenarios for mobility in India.
          Our core focus though lies in the comparison between a BAU and a 30
          per cent EV sales scenario (EV30), retaining mode-shares as BAU but
          involving a change in the fuel mix. We also contrast scenarios where a
          30 per cent EV penetration is achieved under different mode-shares in
          serving the projected passenger travel demand, against the BAU.
          However, we assume occupancy ratio, vehicle ownership vs usage and
          average trip lengths to be constant, in the absence of robust data.
          This being another limitation to the study and hence estimates in all
          three scenarios remain conservative.
        </p>
      </div>
    </div>
  );
}

export default index;
