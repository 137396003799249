import React, { useState } from "react";
import { Scrollama, Step } from "react-scrollama";
import injectSheet from "react-jss";
import LineBarChart from "./LineBarChart";

const styles = {
  main: {
    display: "grid",
    fontFamily: "Helvetica",
    gridTemplateColumns:'35% 1fr' ,
    width:'100%'
  },
  graphic: {
    position: "sticky",
    width: "100%",
    padding: "5rem 0",
    height:600,
    top: "90px",
    "& p": {
      fontSize: "5rem",
      textAlign: "center",
      color: "#fff",
    },
  },
  scroller: {
    display:'grid',
    paddingTop:300
  },
  step: {
    margin: "0 auto 2rem auto",
    paddingBottom: 500,
    "& p": {
      textAlign: "center",
      padding: "1rem",
      fontSize: "1.5rem",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
};


const ScrollamaComp = ({ classes ,NewData }) => {
  const [data, setData] = useState(0);


  const onStepEnter = ({ element, data }) => {
    setData(data);
  };

  console.log("dataaa",data)

  return (
    <div className={classes.main}>
      <div className={classes.scroller}>
        <Scrollama onStepEnter={onStepEnter}  offset={0.45} >
          {NewData.map((value,index) => (
            <Step data={value.value} key={value}>
             <h3 className={classes.step}>{value?.heading}</h3>
            </Step>
          ))}
        </Scrollama>
      </div>
      <div className={classes.graphic}>
      <LineBarChart 
      data={data} 
      />
       
      </div>
    </div>
  );
};

export default injectSheet(styles)(ScrollamaComp);
