import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';


const data = [
    {
      name: 'BAU',
      uv: 1,
      pv: 128,
      amt: 7,
    },
    {
      name: 'EV30',
      uv: 2,
      pv: 108,
      amt: 6,
    },
    {
      name: 'High PT-EV30',
      uv: 5,
      pv: 89,
      amt: 5,
    },
    {
      name: 'High PV-EV30',
      uv: 5,
      pv: 132,
      amt: 5,
    },
    {
      name: 'Shared EV30',
      uv: 5,
      pv: 98,
      amt: 5,
    }
  ];
const Example = () => {
    
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" stackId="a" fill="#4C3D90" barSize={40}>
          <LabelList dataKey="pv" position="top" stroke="#4C3D90" />
        </Bar>
        <Bar dataKey="amt" stackId="a" fill="#8F78F7" barSize={40}>
          <LabelList dataKey="amt" position="top" stroke="#4C3D90" />
        </Bar>
        <Bar dataKey="amt" stackId="a" fill="#E3E5FF" barSize={40}>
          <LabelList dataKey="amt" position="top" stroke="#4C3D90" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
