import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';



const Example = ({data,width}) => {
  return (
    <ResponsiveContainer width={width || '100%'} height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" stackId="a" fill="#4C3D90" barSize={40}>
          <LabelList dataKey="pv" position="top" stroke="#4C3D90" />
        </Bar>
        <Bar dataKey="amt" stackId="a" fill="#8F78F7" barSize={40}>
          <LabelList dataKey="amt" position="top" stroke="#4C3D90" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
