import React from "react";
import "./transport.css";
import AreaChart from "./AreaChart";
import Barchart from "./Barchart";
import MainAnimation from "./MainAnimation";

function index() {
  const data = [
    [
      {
        name: "2-Wheeler",
        uv: 21568.43,
      },
      {
        name: "3-Wheeler",
        uv: 1332.85,
      },
      {
        name: "4-Wheeler",
        uv: 14891.31,
      },
      {
        name: "E- buses",
        uv: 118.52,
      },
    ],
    [
      {
        name: "2-Wheeler",
        uv: 21568.43,
      },
      {
        name: "3-Wheeler",
        uv: 1332.85,
      },
      {
        name: "4-Wheeler",
        uv: 14891.31,
      },
      {
        name: "E- buses",
        uv: 868,
      },
    ],
    [
      {
        name: "EV",
        uv: 5846.94,
      },
      {
        name: "ICE",
        uv: 27415.37,
      },
    ],
    [
      {
        name: "EV",
        uv: 55760,
      },
      {
        name: "ICE",
        uv: 74800,
      },
    ],
  ];

  const data2 = [
    {
      name: 'High PV EV30',
      uv: 76,
      pv: 55,
      amt: 76,
    },
    {
      name: 'BAU',
      uv: 83,
      pv: 41,
      amt: 83,
    },
    {
      name: 'EV 30',
      uv: 73,
      pv: 35,
      amt: 73,
    },
    {
      name: 'Shared EV30',
      uv: 71,
      pv: 27,
      amt: 71,
    },
    {
      name: 'High PT- EV30',
      uv: 67,
      pv: 22,
      amt: 67,
    },
  ];
  return (
    <div style={{marginBottom:100,marginTop:80}}>
      <h1 className="txtHeading">Transport Vehicles and Energy Efficiencies</h1>
      <div className="mainContainer">
        <div style={{ marginLeft: 50 }} className="image-mainContainer">
          <AreaChart data={data[0]} width={'80%'}/>
        </div>
        <div className="text-mainContainer">
          <p className="subMainHeading2" >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </p>
        </div>
      </div>
      <div className="mainContainer">
        <div style={{ marginLeft: 50 }} className="text-mainContainer">
          <p className="subMainHeading2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </p>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column",marginLeft:100 }}
          className="image-mainContainer"
        >
          <AreaChart data={data[1]} width={'80%'}/>
          <p style={{ width: "50%", marginLeft: 70 }} className="subMainHeading2">
            EV: CO2 emission factor = 0.82 toe/MWh ICE: CO2 emission factor = 44
            g/km (2W); 92 g/km (3W); 231 g/km (4W); 1056g/km (Bus)
          </p>
        </div>
      </div>
      <div className="mainContainer">
        <div
          style={{ display: "flex", flexDirection: "column",marginLeft:100 }}
          className="image-mainContainer"
        >
          <AreaChart data={data[2]} width={'80%'}/>
          <p style={{ width: "50%", marginLeft: 70 }} className="subMainHeading2">
            * For EV : Fuel consumption equivalent of electricity consumption is
            used EV: Electric Vehicle E-buses: Electric Buses ICE: Internal
            Combustion Engine
          </p>
        </div>
        <div className="text-mainContainer">
          <p className="subMainHeading2" style={{marginRight:100}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </p>
        </div>
      </div>
      <div className="mainContainer">
        <div style={{ marginLeft: 50 }} className="text-mainContainer">
          <p className="subMainHeading2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
          </p>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column",marginLeft:100 }}
          className="image-mainContainer"
        >
          <AreaChart data={data[3]} width={'80%'}/>
          <p style={{ width: "50%", marginLeft: 70 }} className="subMainHeading2">
            EV: Conversion factor for kWh to toe (toe/kWh) =0.0000859844 ICE:
            Tonne to toe conversion factor = 1.05 toe (for 2W, 3W, 4W) ICE:
            Tonne to toe conversion factor = 1.01 toe (for Buses)
          </p>
        </div>
      </div>
      <div>
        <h1 className="txtHeading">Lorem ipsum dolor sit amet</h1>
        <h3
          className="lowersubMainHeading"
          style={{ textAlign: "center", marginTop: 20 }}
        >
          The consumption of petrol and diesel is highest in the high private
          vehicle mobility scenario and lowest in the high public transport
          mobility scenario
        </h3>
        <div className="mainContainer2">
          <div style={{ marginLeft: 50 }} className="image-mainContainer3">
            <Barchart data={data2} />
          </div>
          <div className="text-mainContainer">
            <p className="subMainHeading2">
              While the intent is to bring down the consumption of petrol and
              diesel through changes in mode-share and fuel mix (Figure 18), it
              also has to be borne in mind that the petroleum sector is a
              significant contributor to revenues for the centre and states in
              the form of taxes and dividend income. The total revenue realised
              from taxes on petrol and diesel was INR 5,76,000 crore in FY19,
              with 60 per cent of these revenues going to the centre and the
              remaining to the states (PPAC 2020). Tax on fuel constitutes 15–20
              per cent of tax revenue for the majority of the states (RBI 2019).
              Hence, we explored how changing petrol and diesel consumption
              levels could impact central and state government revenues in 2030.
            </p>
            <p className="subMainHeading2">
              The central government would lose 15–31 per cent of potential
              petroleum tax revenues (otherwise obtained in a BAU scenario) in
              2030 in multiple scenarios we assume for 2030 (Figure 18). The
              combined state government revenues from petroleum tax could dip by
              15–33 per cent under various scenarios compared to BAU.
            </p>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: 20 ,paddingLeft:50,paddingRight:50}}>
          <h3 className="lowersubMainHeading">
            On the other hand, in a high private vehicle scenario, the central
            and state governments, respectively, gain a revenue of 4 and 8 per
            cent in the form of fuel taxes. The loss or gain in fuel tax
            revenue, which is a reflection of consumer spend on petrol and
            diesel, should be viewed from a larger macroeconomic context. The
            consumer spending less on fuel is left with a higher disposable
            income, which, in turn, is expected to increase the consumption of
            other goods and services in the economy (Cambridge Econometrics
            2015).
          </h3>
        </div>
      </div>
    <MainAnimation/>
    </div>
  );
}

export default index;
