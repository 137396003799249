import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const ChartExample = ({ data ,height , width }) => {
  const barFill = 'linear-gradient(180deg, #554AD8 0%, rgba(116, 130, 223, 0.69) 89.48%, rgba(140, 158, 213, 0.30) 115.43%)';

  const CustomBar = (props) => {
    const { x, y, width, height } = props;

 
    return (
      <g>
        <defs>
          <linearGradient id={`barFill-${props.index}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#554AD8' }} />
            <stop offset="89.48%" style={{ stopColor: 'rgba(116, 130, 223, 0.69)' }} />
            <stop offset="115.43%" style={{ stopColor: 'rgba(140, 158, 213, 0.30)' }} />
          </linearGradient>
        </defs>
        <path
          fill={`url(#barFill-${props.index})`}
          d={`M${x},${y}A20,20,0,0,1,${x + width},${y}L${x + width},${y + height}L${x},${y + height}Z`}
        />
      </g>
    );
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, value } = props;
    // Adjust the y position to move the label upwards
    const adjustedX = x - 50;
    const adjustedY = y - 40; // Example adjustment, you can adjust as needed
  
    return (
      <foreignObject x={adjustedX} y={adjustedY} width="100" height="100">
        <div style={{ backgroundColor: '#4C3D90', padding: 5, borderRadius: 5 }}>
          <text style={{color:'white',margin:20}} fontSize={16} fontWeight={'800'} textAnchor="left">
            {value}
          </text>
        </div>
      </foreignObject>
    );
  };


  return (
    <ResponsiveContainer width={"80%"} height={"70%"}>
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="uv"
          barSize={30}
          shape={<CustomBar />}
        />
        <Line type="monotone" dataKey="uv" stroke="#FFD54F" strokeWidth={3}>
          <LabelList dataKey="uv" position="top" content={renderCustomizedLabel} />
        </Line>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartExample;
