import React from "react";
import img1 from "../images/Intro.png";
import img2 from "../images/intro2.png";
import img3 from "../images/intro3.png";
import img4 from "../images/intro4.png";
import img5 from "../images/intro5.png";
import img6 from "../images/intro6.png";
import { MotionAnimate } from "react-motion-animate";

const Introduction = () => {
  return (
    <div className="app">
      <nav className="navbar">
        <div className="navbar-buttons">
          <p>OverView</p>
          <p>Impact</p>
          <p>EV Adoption</p>
          <p>Conclusion</p>
        </div>
      </nav>
      <header className="app-header">
      <MotionAnimate
          delay={0.4}
          speed={2}
          ease={[0.75, 0.45, 0.53, 0.94]}
          reset={true}
        >
        <h1 className="top-heading">
          Visualizing the road ahead: Mapping India's EV Landscape
        </h1>
          <img style={{ width: "70%", height: "40%" }} src={img1} alt="img1" />
        </MotionAnimate>
        <MotionAnimate
          animation="fadeInUp"
          reset={true}
          distance={200}
          delay={1}
          speed={2}
        >
          <img
            style={{ width: "55%", height: "30%", marginTop: 10 }}
            src={img2}
            alt="img1"
          />
        </MotionAnimate>
        <div className="bottonHeader">
          <MotionAnimate
            delay={0.4}
            speed={2}
            ease={[0.75, 0.45, 0.53, 0.94]}
            reset={true}
          >
            <div style={{ marginRight: 80 }}>
              <img style={{ width: "85%" }} src={img3} alt="img1" />
            </div>
          </MotionAnimate>
          <MotionAnimate
            animation="fadeInUp"
            reset={true}
            distance={200}
            delay={1}
            speed={2}
          >
            <div className="column-container">
              <div>
                <img
                  style={{ width: "80%", marginBottom: 10 }}
                  src={img6}
                  alt="img1"
                />
              </div>
              <div>
                <img
                  style={{ width: "80%", marginBottom: 10 }}
                  src={img5}
                  alt="img1"
                />
              </div>
              <div>
                <img
                  style={{ width: "80%", marginBottom: 10 }}
                  src={img4}
                  alt="img1"
                />
              </div>
            </div>
          </MotionAnimate>
        </div>
      </header>
    </div>
  );
};

export default Introduction;
