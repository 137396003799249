import React from 'react';
import './App.css';

import Introduction from './component/Introduction';
import PollutionMap from './component/pollition/PollutionMap';
import Scrollama from './component/scrollama/index'
import EvAdoption from './component/EvAdoption/index'
import TransportVehicle from './component/TransportVehicle/'
import EvStandards from './component/EvStandards'
import SafeScheme from './component/SafeScheme'
import EvMarketTrend from './component/EvMarketTrend'
import BarChartComp from './component/scrollama/BarChartComp';
import HorizontalScroll from './component/HorizontalScroll';

const App = () => {
  return (
    <>
 
   <Introduction/>
   <PollutionMap/>
   <Scrollama/>
   <EvAdoption/>
   <TransportVehicle/>
   <EvStandards/>
   <HorizontalScroll/>
   {/* <SafeScheme/> */}
   <EvMarketTrend/>

   </>
  );
};

export default App;
