import React from "react";
import "./index.css";
import BarChartComp from "./BarChartComp";
import ScrollmaComp from "./ScrollmaComp";

function Index() {
 
  return (
    <div className="background-image-container">
      <h1 className="heading">Energy Impact: Traditional vs. EVs</h1>

      <div
        style={{ display: "flex", justifyContent: "center", maxWidth: "50%" }}
      >
        <p className="dics">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi.
        </p>
      </div>

      <div>
        <h3 className="subHeading">
          Projection of EV sales of 4 per cent in a BAU scenario and a scenario
          with 30 per cent EV sales by 2030{" "}
        </h3>
      </div>
      {/* <div
        style={{
          // height: "80%",
          width: "100%",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {data.map((item, index) => {
          return (
            <div
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img
                src={item?.img}
                alt="bar"
                style={{
                  height: index === 0 || index === 2 ? 80 : 130,
                  width: index === 0 || index === 2 ? 120 : 140,
                }}
              />
              <BarChartComp data={item?.value} />
            </div>
          );
        })}
      </div> */}
      <ScrollmaComp/>
      <div
        style={{ display: "flex", justifyContent: "center", maxWidth: "60%",marginTop:30,marginLeft:'7%'}}
      >
        <p className="dics">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi.
        </p>
      </div>
    </div>
  );
}

export default Index;
