import React from "react";
import CarVector from "../../images/CarVector.svg";
import EVDiagram from "../../images/EVDiagram.svg";
import "./index.css";
import Barchart from "./Barchart";
import Stare from "./Stare/Index";
import { MotionAnimate } from "react-motion-animate";
import Scrollama from "./Scrollama";

function Index() {
  const sentenceStyle = {
    display: "inline",
    color: "#7291DE",
  };

  return (
    <div className="pollution-map-container">
      <MotionAnimate
        animation="fadeInUp"
        reset={true}
        distance={200}
        delay={1}
        speed={1}
      >
        <h1 className="heading">EV adoption in India</h1>
      </MotionAnimate>

      <div
        style={{
          display: "flex",
          alignSelf: "center",
          width: "55%",
          marginTop: 30,
        }}
      >
        <MotionAnimate
          animation="fadeInUp"
          reset={true}
          distance={200}
          delay={1.5}
          speed={1}
        >
          <p className="subHeading">
            The growing pollution in India and its agreement to the Paris
            Agreement (which aims to limit global warming to 1.5°C by the end of
            this century) has made the country set a target for clean energy.
            India has committed to achieve a net zero emissions economy by 2070.
          </p>
        </MotionAnimate>
      </div>
      <MotionAnimate
        animation="fadeInUp"
        reset={true}
        distance={200}
        delay={2}
        speed={1}
      >
        <div style={{ display: "flex", position: "relative", marginTop: "5%" }}>
          <p
            className="subHeading2"
            style={{ position: "absolute", top: 20, left: 100, width: "30%" }}
          >
            The Indian government has also set a target to achieve 30 percent
            electrification of the country’s vehicle fleet by 2030. Transition
            to electric vehicles (EV) has immense potential and is likely to
            have a wide-ranging impact on the environmental gains from reduced
            local air pollutants and greenhouse gas (GHG) emissions as well as
            the economy 
          </p>
          <img src={CarVector} alt="car" style={{ width: "100%" }} />
        </div>
      </MotionAnimate>
      <Scrollama />
      <div className="container">
        <div className="image-container">
          <div style={{ width: "60%", marginRight: 90, marginTop: 50 }}>
            <h1>Lorem ipsum dolor sit amet</h1>
            <div>
              <p style={{ margin: 0, padding: 0 }}>
                EV prevention to rise across segments annual sales to reach{" "}
                <span style={sentenceStyle}>
                  from present 28 mn to 78 mn by 2030.
                </span>
              </p>
              <p style={{ margin: 0, padding: 0, marginTop: "7%" }}>
                EV-ICE price gap narrow with proliferation of EVs, increasing
                R&D spends, standardization of technology{" "}
                <span style={sentenceStyle}>
                  and reduction in battery costs, Additionally, incentives and
                  charging infrastructure expansion spur initial adoption
                </span>
              </p>
            </div>
            <div style={{ marginTop: "50%" }}>
              <p
                style={{ fontSize: 14, fontWeight: 300, margin: 0, padding: 0 }}
              >
                *Projections of EVs vary between sources, but were consistently
                revised upwards anywhere from 25% to 260% since 2015. As per
                IEA. Oem declarations continue to outpace the projections
                displayed here
              </p>
            </div>
          </div>
        </div>
        <div style={{width:'60%'}}>
          <img src={EVDiagram} alt="Ev" style={{ width: "75%" }} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 className="heading">
          Government Initiative in bossting EV Adoption
        </h1>

        <div style={{ width: "55%", marginTop: 30, textAlign: "center" }}>
          <p className="subHeading">
            The growing pollution in India and its agreement to the Paris
            Agreement (which aims to limit global warming to 1.5°C by the end of
            this century) has made the country set a target for clean energy.
            India has committed to achieve a net zero emissions economy by 2070.
          </p>
        </div>
        {/* <div>
          <Stare />
        </div> */}
        <img
          src={require("../../images/stepImage.png")}
          alt="car"
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );
}

export default Index;
