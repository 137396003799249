import React from "react";
import "./transport.css";

export default function MainAnimation() {
  return (
    <>
      <div class="bg"></div>
      <div class="bg bg2"></div>
      <div class="bg bg3"></div>
      {/* <div class="content">
        <h1>Sliding Diagonals Background Effect</h1>
      </div> */}
    </>
  );
}