import React, { useState } from "react";
import { Scrollama, Step } from "react-scrollama";
import injectSheet from "react-jss";
import BarChartComp from "./BarChartComp";

const styles = {
  main: {
    display: "grid",
    fontFamily: "Helvetica",
    gridTemplateColumns:'35% 1fr' ,
    width:'100%'
  },
  graphic: {
    position: "sticky",
    width: "100%",
    padding: "5rem 0",
    height:550,
    top: "70px",
    "& p": {
      fontSize: "5rem",
      textAlign: "center",
      color: "#fff",
    },
  },
  scroller: {
    display:'grid',
    paddingTop:200
  },
  step: {
    margin: "0 auto 2rem auto",
    paddingBottom: 600,
    "& p": {
      textAlign: "center",
      padding: "1rem",
      fontSize: "1.5rem",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
};

const NewData = [
    {
      img: require("../../images/co.png"),
      value: [
        {
          name: "BAU",
          uv: 3895,
        },
        {
          name: "EV30",
          uv: 4855,
        },
        {
          name: "High PT",
          uv: 5735,
        },
        {
          name: "High PT- EV30",
          uv: 3125,
        },
        {
          name: "High PV",
          uv: 6085,
        },
        {
          name: "High PV EV30",
          uv: 5040,
        },
        {
          name: "Shared",
          uv: 4231,
        },
        {
          name: "Shared EV30",
          uv: 5212,
        },
      ],
    },
    {
      img: require("../../images/nox.png"),
      value: [
        {
          name: "BAU",
          uv: 597,
        },
        {
          name: "EV30",
          uv: 493,
        },
        {
          name: "High PT",
          uv: 511,
        },
        {
          name: "High PT- EV30",
          uv: 431,
        },
        {
          name: "High PV",
          uv: 681,
        },
        {
          name: "High PV EV30",
          uv: 569,
        },
        {
          name: "Shared",
          uv: 536,
        },
        {
          name: "Shared EV30",
          uv: 449,
        },
      ],
    },
    {
      img: require("../../images/pm.png"),
      value: [
        {
          name: "BAU",
          uv: 39,
        },
        {
          name: "EV30",
          uv: 33,
        },
        {
          name: "High PT",
          uv: 33,
        },
        {
          name: "High PT- EV30",
          uv: 28,
        },
        {
          name: "High PV",
          uv: 47,
        },
        {
          name: "High PV EV30",
          uv: 39,
        },
        {
          name: "Shared",
          uv: 36,
        },
        {
          name: "Shared EV30",
          uv: 30,
        },
      ],
    },
    {
      img: require("../../images/co2.png"),
      value: [
        {
          name: "BAU",
          uv: 401,
        },
        {
          name: "EV30",
          uv: 385,
        },
        {
          name: "High PT",
          uv: 332,
        },
        {
          name: "High PT- EV30",
          uv: 321,
        },
        {
          name: "High PV",
          uv: 564,
        },
        {
          name: "High PV EV30",
          uv: 356,
        },
        {
          name: "Shared",
          uv: 565,
        },
        {
          name: "Shared EV30",
          uv: 449,
        },
      ],
    },
  ];

const ScrollamaComp = ({ classes }) => {
  const [data, setData] = useState(0);


  const onStepEnter = ({ element, data }) => {
    setData(data);
  };

  return (
    <div className={classes.main}>
      <div className={classes.scroller}>
        <Scrollama onStepEnter={onStepEnter}  offset={0.8} >
          {NewData.map((value,index) => (
            <Step data={value} key={value}>
             <img
                src={value.img}
                alt="bar"
                className={classes.step}
                style={{
                    height: index === 0 || index === 2 ? 80 : 130,
                    width: index === 0 || index === 2 ? 120 : 140,
                  }}
              />
              
            </Step>
          ))}
        </Scrollama>
      </div>
      <div className={classes.graphic}>
      <BarChartComp width={'85%'}
      data={data} style={{width:'100%'}}
      />
       
      </div>
    </div>
  );
};

export default injectSheet(styles)(ScrollamaComp);
