import React from 'react';
import './PollutionMap.css'; // Import your CSS file
import IndiaMap from '../IndiaMap';

function PollutionMap() {
  return (
    <div className="India-Map">
      <h1 className="heading">The pollution hotspots in India 2024</h1>
      <div style={{display:'flex',flexDirection:'row'}}> 
        <div style={{width:'80%',display:'flex',justifyContent:'flex-end'}}>
            {/* <img src={require('../../images/india.png')} alt='india' style={{width:'80%'}} /> */}
            <IndiaMap />
        </div>
        <div style={{width:'50%',display:'flex',flexDirection:'column'}}>
        <div >
            <p className='mapDisc'>Lorem ipsum dolor sit amet consectetur. Tellus nulla sem sit diam eget ullamcorper nec vel. Ac volutpat mattis iaculis ipsum cursus a. In quis egestas vestibulum quam laoreet tincidunt.</p>
        </div>
        <div className="images-container">
          <img src={require('../../images/tag5.png')} alt='tag1' />
          <img src={require('../../images/tag4.png')} alt='tag2' />
          <img src={require('../../images/tag3.png')} alt='tag3' />
          <img src={require('../../images/tag2.png')} alt='tag4' />
          <img src={require('../../images/tag1.png')} alt='tag5' />
        </div>
        </div>
      </div>
    </div>
  );
}

export default PollutionMap;

