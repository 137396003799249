import React, { useState } from 'react';
import { HorizontalSection, ScrollContainer } from 'react-nice-scroll';
import 'react-nice-scroll/dist/styles.css';
import LineBarChart from './SafeScheme/LineBarChart';
import "./SafeScheme/index.css";

const HorizontalScroll = () => {
  // State to manage the current data for the line chart
  const [currentDataIndex, setCurrentDataIndex] = useState(0);

  const data = [
    {
      heading: "CO2 Emission Performance",
      value: [
        {
          name: "2017-18",
          uv: 122.71,
        },
        {
          name: "2018-19",
          uv: 122.36,
        },
        {
          name: "2019-20",
          uv: 122.6,
        },
        {
          name: "2020-21",
          uv: 122.4,
        },
      ],
    },
    {
      heading: "CO2 Emission Performance",
      value: [
        {
          name: "2017-18",
          uv: 5227850.3,
        },
        {
          name: "2018-19",
          uv: 54493780,
        },
        {
          name: "2019-20",
          uv: 135417363.597,
        },
        {
          name: "2020-21",
          uv: 3890189.7,
        },
      ],
    },
    {
      heading: "Reduction in Petrol Savings per vehicles ",
      value: [
        {
          name: "2017-18",
          uv: 157.28,
        },
        {
          name: "2018-19",
          uv: 159.97,
        },
        {
          name: "2019-20",
          uv: 194.02,
        },
        {
          name: "2020-21",
          uv: 143.93,
        },
      ],
    },
    {
      heading: "Vehicles Production",
      value: [
        {
          name: "2017-18",
          uv: 5227810.3,
        },
        {
          name: "2018-19",
          uv: 7489380,
        },
        {
          name: "2019-20",
          uv: 13541363.597,
        },
        {
          name: "2020-21",
          uv: 3890819.7,
        },
      ],
    },
    {
      heading: "Fuel Savings (toe)",
      value: [
        {
          name: "2017-18",
          uv: 1.24,
        },
        {
          name: "2018-19",
          uv: 1.29,
        },
        {
          name: "2019-20",
          uv: 1.08,
        },
        {
          name: "2020-21",
          uv: 0.92,
        },
      ],
    },
  ];

  // Function to handle text change and update the line chart data
  const handleTextChange = (index) => {
    setCurrentDataIndex(index);
  };

  return (
    <div>
<h1 className="headingTop">CAFE scheme performance indicators</h1>
      <p className="subHeadingTop">
        Lorem ipsum dolor sit amet consectetur. Pharetra est etiam amet arcu.
        Sit sollicitudin quam sed quis vitae viverra ullamcorper id nulla.
      </p>
      <select className="dropDown">
        <option>Assessment Year</option>
      </select>
    <ScrollContainer  >
      
      <HorizontalSection>
        {/* Map through your data and create items with onClick to change the text and update line chart data */}
        {data.map((item, index) => (
          <div style={{display:'flex',flexDirection:'column',height:'100%',alignItems:'center',width:1700}}>
          <div
            key={index}
            style={{justifyContent:'center',padding:50,height:10}}
            className="ns-horizontal-section__item"
            onClick={() => handleTextChange(index)}
            >
            {item.heading}
          </div>
           <LineBarChart data={data[currentDataIndex].value} height={"50%"} width={"70%"}/>
           </div>
        ))}
      </HorizontalSection>

     
    </ScrollContainer>
        </div>
  );
};

export default HorizontalScroll;
